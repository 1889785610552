import classNames from 'classnames';
import {useLayoutEffect, useState} from 'react';

import {useGameData} from './GameDataContext';

import css from './Calendar.module.scss';

const FIRST_PUZZLE_DATE = new Date(2018, 5, 9, 0, 0, 0, 0);

const DateInput = ({className, onChange, range, validRange, value, id}) => {
  const [start, end] = range;
  const [validStart, validEnd] = validRange;

  const options = [];

  for (let i = start; i <= end; i++) {
    options.push(
      <option disabled={i < validStart || i > validEnd} key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <label htmlFor={id} className={css.selectLabel}>
      <select className={css.select} value={value} id={id} onChange={onChange}>
        {options}
      </select>
    </label>
  );
};

const getLastDayOfMonth = ({year, month}) => {
  return new Date(year, month + 1, 0).getDate();
};

const getYearRange = ({todayYear}) => {
  return [FIRST_PUZZLE_DATE.getFullYear(), todayYear];
};

const getMonthRange = ({year, todayYear, todayMonth}) => {
  if (year === FIRST_PUZZLE_DATE.getFullYear()) {
    return [FIRST_PUZZLE_DATE.getMonth() + 1, 12];
  }

  if (year === todayYear) {
    return [1, todayMonth];
  }

  return [1, 12];
};

export const Calendar = ({showCalendar}) => {
  const {game} = useGameData();

  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  const gameDate = new Date(game.displayDate);
  gameDate.setHours(0);
  gameDate.setMinutes(0);
  gameDate.setSeconds(0);
  gameDate.setMilliseconds(0);

  const currentYear = gameDate.getFullYear();
  const currentMonth = gameDate.getMonth() + 1;
  const currentDay = gameDate.getDate();

  const [{year, month, day}, rawSetDate] = useState({
    year: currentYear,
    month: currentMonth,
    day: currentDay,
  });

  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const calendar = document.getElementById('calendar');
    const {height: calendarHeight} = calendar.getBoundingClientRect();

    setHeight(calendarHeight);
  }, [setHeight, month, year]);

  const values = {
    year,
    month,
    day,
    currentYear,
    currentMonth,
    currentDay,
    todayYear: today.getFullYear(),
    todayMonth: today.getMonth() + 1,
    todayDay: today.getDate(),
  };

  const setDate = (e) => {
    rawSetDate({
      year,
      month,
      day,
      [e.target.id]: Number(e.target.value),
    });
  };

  const firstDayOfMonth = new Date(year, month - 1, 1, 0, 0, 0);
  const lastDayOfMonth = new Date(year, month, 0, 0, 0, 0);

  const calendarRows = [];

  const nextDay = new Date(firstDayOfMonth);
  nextDay.setDate(nextDay.getDate() - nextDay.getDay());

  while (true) {
    if (!nextDay.getDay()) {
      if (nextDay > lastDayOfMonth) {
        break;
      }

      calendarRows.push([]);
    }

    const className = classNames({
      [css.cell]: true,
      [css.cell_faded]: nextDay.getMonth() + 1 !== month,
      [css.cell_disabled]: nextDay > today || nextDay < FIRST_PUZZLE_DATE,
      [css.cell_today]: nextDay.toISOString() === gameDate.toISOString(),
    });

    calendarRows.at(-1).push(
      <td className={className} key={nextDay.getDate()}>
        {nextDay.getDate()}
      </td>
    );
    nextDay.setDate(nextDay.getDate() + 1);
  }

  const tbody = calendarRows.map((row, i) => <tr key={i}>{row}</tr>);

  const style = {
    marginTop: showCalendar ? 0 : `${-1 * height}px`,
  };

  return (
    <div className={css.calendarWrapper} id="calendar" style={style}>
      <form className={css.form}>
        <DateInput
          id="month"
          onChange={setDate}
          range={[1, 12]}
          validRange={getMonthRange(values)}
          value={month}
        />
        <DateInput
          id="year"
          onChange={setDate}
          range={[FIRST_PUZZLE_DATE.getFullYear(), today.getFullYear()]}
          validRange={getYearRange(values)}
          value={year}
        />
      </form>
      <table>
        <tbody>{tbody}</tbody>
      </table>
    </div>
  );
};
