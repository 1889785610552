import classNames from 'classnames';
import {useEffect, useState} from 'react';

import {get, set} from './db';
import {useGameData} from './GameDataContext';
import {useHints} from './HintContext';
import {useWordList} from './WordListContext';

import css from './Entry.module.scss';

const Hex = ({letter, isCenter, onClick, i}) => {
  const className = classNames({
    [css.hex]: true,
    [css.hex_center]: isCenter,
    [css[`hex-${i}`]]: true,
  });

  return (
    <button className={className} id={letter} onClick={() => onClick(letter)}>
      {letter}
    </button>
  );
};

const flashElement = (id, className, callback) => {
  const el = document.getElementById(id);
  el.classList.add(className);
  setTimeout(() => {
    if (callback) {
      callback();
    }

    el.classList.remove(className);
  }, 300);
};

const KeyboardHandler = ({
  onEnter,
  onLetterClick,
  removeLastLetter,
  validLetters,
}) => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      console.log(e);
      e.preventDefault();
      if (e.key === 'Enter') {
        onEnter();
        flashElement(e.key, css.control_keyed);
      } else if (validLetters.includes(e.key)) {
        onLetterClick(e.key);
        flashElement(e.key, css.hex_keyed);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Backspace') {
        removeLastLetter();
        flashElement(e.key, css.control_keyed);
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [onEnter, onLetterClick, validLetters]);

  return null;
};

const CurrentWord = ({word, centerLetter}) => {
  let contentArray = [];

  for (let letter of word) {
    contentArray.push(
      letter === centerLetter ? (
        <span
          className={css.centerLetter}
          key={`${letter}-${contentArray.length}`}
        >
          {letter}
        </span>
      ) : (
        letter
      )
    );
  }

  return (
    <div className={css.currentWord} id="currentWord">
      {contentArray.length ? contentArray : '\u00A0'}
    </div>
  );
};

export const Entry = () => {
  const [currentWord, setCurrentWord] = useState('');
  const {game} = useGameData();
  const {addWord, wordList} = useWordList();
  const [outerLetters, setOuterLetters] = useState([...game.outerLetters]);
  const {showGrid, showHints, showWordList, setShowGrid} = useHints();

  const {answers} = game;

  const onLetterClick = async (letter) => {
    setCurrentWord(currentWord + letter);

    const {elapsedSeconds} = await get({gameId: game.id, elapsedSeconds: true});

    await set({
      gameId: game.id,
      elapsedSecondsLastInteraction: elapsedSeconds,
    });
  };

  const onEnter = () => {
    let flashClass;
    if (wordList.includes(currentWord)) {
      flashClass = css.currectWord_repeat;
    } else if (answers.includes(currentWord)) {
      addWord(currentWord);
      flashClass = css.currentWord_success;
    } else {
      flashClass = css.currentWord_fail;
    }

    flashElement('currentWord', flashClass, () => {
      setCurrentWord('');
    });
  };

  const removeLastLetter = () => {
    setCurrentWord(currentWord.substring(0, currentWord.length - 1));
  };

  const shuffle = () => {
    setOuterLetters(
      outerLetters
        .map((value) => ({value, sort: Math.random()}))
        .sort((a, b) => a.sort - b.sort)
        .map(({value}) => value)
    );
  };

  const shouldUseSpacer = !showHints && !showWordList;

  return (
    <>
      {shouldUseSpacer && <div>&nbsp;</div>}

      <CurrentWord centerLetter={game.centerLetter} word={currentWord} />

      <div className={css.hexContainer}>
        <Hex letter={outerLetters[0]} onClick={onLetterClick} />
        <div className={css.hexRow}>
          <Hex letter={outerLetters[1]} onClick={onLetterClick} />
          <Hex letter={outerLetters[2]} onClick={onLetterClick} />
        </div>
        <Hex
          isCenter={true}
          letter={game.centerLetter}
          onClick={onLetterClick}
        />
        <div className={css.hexRow}>
          <Hex letter={outerLetters[3]} onClick={onLetterClick} />
          <Hex letter={outerLetters[4]} onClick={onLetterClick} />
        </div>
        <Hex letter={outerLetters[5]} onClick={onLetterClick} />
      </div>

      {shouldUseSpacer && <div>&nbsp;</div>}

      <div className={css.controlRow}>
        <div className={css.controlContainer}>
          <button
            className={css.control}
            id="Backspace"
            onClick={removeLastLetter}
          >
            delete
          </button>
        </div>
        <div className={css.controlContainer}>
          <button className={css.control} onClick={shuffle}>
            shuffle
          </button>
        </div>
        <div className={css.controlContainer}>
          <button className={css.control} id="Enter" onClick={onEnter}>
            enter
          </button>
        </div>

        {showHints && showWordList && (
          <div
            className={classNames(css.toggle, css.toggle_grid)}
            onClick={() => setShowGrid(!showGrid)}
          >
            {showGrid ? '☰' : '▦'}
          </div>
        )}
      </div>

      <KeyboardHandler
        onLetterClick={onLetterClick}
        onEnter={onEnter}
        removeLastLetter={removeLastLetter}
        validLetters={game.validLetters}
      />
    </>
  );
};
