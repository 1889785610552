// https://ni78fd.deta.dev/todays-game
'use strict';
import classNames from 'classnames';
import {createContext, useContext, useEffect, useState} from 'react';
import axios from 'axios';

import bee from './bee.png';

import css from './GameDataContext.module.scss';
import {get} from './db';

const GameDataContext = createContext({});

const getGameHelpers = (game) => {
  game.answers.sort();
  game.validLetters.sort();

  const maxLength = Math.max(...game.answers.map((word) => word.length));
  const grid = ['', ...game.validLetters].map((letter, i) => {
    if (!i) {
      return [
        '',
        ...[...new Array(maxLength - 3)].map((_, i) => {
          return i + 4;
        }),
      ];
    }

    return [letter, ...[...new Array(maxLength - 3).fill(0)]];
  });

  game.answers.forEach((word) => {
    const firstLetter = word[0];
    const letterIndex = game.validLetters.indexOf(firstLetter);
    grid[letterIndex + 1][word.length - 3]++;
  });

  return {
    game,
    grid,
  };
};

const populateGameData = async (setGameData) => {
  const {data} = await axios.get(
    'https://beebuddy-1-m3699482.deta.app/todays-game'
  );
  let {today, yesterday} = data;

  const {game, grid} = getGameHelpers(today);
  const {
    elapsedSecondsLastInteraction: yesterdayElapsedSecondsLastInteraction,
  } = await get({gameId: yesterday.id, elapsedSecondsLastInteraction: true});
  setGameData({game, grid, yesterday, yesterdayElapsedSecondsLastInteraction});
};

export const GameDataProvider = ({children}) => {
  const [gameData, setGameData] = useState(null);
  useEffect(() => {
    populateGameData(setGameData);
  }, [setGameData]);

  if (!gameData) {
    return (
      <div className={css.loadingWrapper}>
        <div className={css.loading}>
          <img className={css.img} src={bee} />
          <div className={css.background} />
        </div>
      </div>
    );
  }

  const calculatePoints = (list, game) => {
    game = game || gameData.game;

    return list.reduce((points, nextWord) => {
      if (nextWord.length === 4) {
        return points + 1;
      }

      if (game.pangrams.includes(nextWord)) {
        return points + nextWord.length + 7;
      }

      return points + nextWord.length;
    }, 0);
  };

  const value = {
    ...gameData,
    calculatePoints,
    totalPoints: calculatePoints(gameData.game.answers),
  };

  return (
    <GameDataContext.Provider value={value}>
      {children}
    </GameDataContext.Provider>
  );
};

export const useGameData = () => useContext(GameDataContext);
