import {createContext, useContext, useEffect, useState} from 'react';
import {get, set} from './db';
import {useGameData} from './GameDataContext';
import {useWordList} from './WordListContext';

const ElapsedTimeContext = createContext({});

let timer;

export const ElapsedTimeProvider = ({children}) => {
  const {game} = useGameData();
  const {wordList} = useWordList();
  const [elapsedSeconds, setElapsedSeconds] = useState(0);

  const value = {elapsedSeconds};

  useEffect(() => {
    const setExistingElapsedTime = async () => {
      const {elapsedSeconds} = await get({
        gameId: game.id,
        elapsedSeconds: true,
      });

      if (elapsedSeconds) {
        setElapsedSeconds(elapsedSeconds);
      }
    };
    setExistingElapsedTime();
  }, []);

  useEffect(() => {
    if (game.answers.length === wordList.length) {
      return;
    }

    clearInterval(timer);
    timer = setInterval(async () => {
      if (document.visibilityState === 'visible') {
        setElapsedSeconds(elapsedSeconds + 1);
        await set({gameId: game.id, elapsedSeconds});
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [setElapsedSeconds, elapsedSeconds, wordList.length]);

  return (
    <ElapsedTimeContext.Provider value={value}>
      {children}
    </ElapsedTimeContext.Provider>
  );
};

export const useElapsedTime = () => useContext(ElapsedTimeContext);
