import classNames from 'classnames';
import {useEffect, useState} from 'react';

import {useGameData} from './GameDataContext';
import {useWordList} from './WordListContext';

import css from './Header.module.scss';
import {useHints} from './HintContext';
import {useTheme} from './ThemeContext';
import {Calendar} from './Calendar';
import {useElapsedTime} from './ElapsedTimeContext';
import {useDarkMode} from './darkMode';

const RANKING = [
  ['Beginner', 0],
  ['Good Start', 2],
  ['Moving Up', 5],
  ['Good', 8],
  ['Solid', 15],
  ['Nice', 25],
  ['Great', 40],
  ['Amazing', 50],
  ['Genius', 70],
  ['Queen Bee', 100],
].reverse();

const Item = ({name, value}) => {
  return (
    <div className={css.item}>
      <div>{name}</div>
      <div className={css.value}>{value}</div>
    </div>
  );
};

const MenuItem = ({children, className, onClick, title}) => {
  const menuItemClassName = classNames({
    [css.menuItem]: true,
    [className]: !!className,
  });

  return (
    <div className={menuItemClassName} onClick={onClick}>
      <div className={css.left}>{title}</div>
      <div className={css.right}>{children}</div>
    </div>
  );
};

const ToggleItem = ({title, onClick: originalOnClick, isChecked}) => {
  const toggleItemClassName = classNames({
    [css.menuItem_checked]: isChecked,
  });

  const onClick = () => {
    originalOnClick(!isChecked);
  };

  return (
    <MenuItem className={toggleItemClassName} onClick={onClick} title={title}>
      <div className={css.checkbox}>{isChecked ? '✓' : null}</div>
    </MenuItem>
  );
};

const getInterestingGameData = (game, wordList, calculatePoints) => {
  const points = calculatePoints(wordList, game);
  const totalPoints = calculatePoints(game.answers, game);
  const rankingIndex = RANKING.findIndex(([_, threshold]) => {
    return points >= totalPoints * (threshold / 100);
  });

  const [ranking, _] = RANKING[rankingIndex];

  const [nextRank, nextRankingThreshold] = RANKING[rankingIndex - 1] || [
    null,
    null,
  ];

  const nextRankingPoints = Math.ceil(
    totalPoints * (nextRankingThreshold / 100)
  );

  return {points, ranking, nextRank, nextRankingPoints};
};

const getTime = (elapsedSeconds) => {
  const hours = Math.floor(elapsedSeconds / 3600);
  const minutes = Math.floor((elapsedSeconds - hours * 3600) / 60);
  const seconds = elapsedSeconds - hours * 3600 - minutes * 60;

  let timeArr = [];
  if (hours) {
    timeArr.push(hours);
  }

  if (hours || minutes) {
    timeArr.push(minutes);
  }

  timeArr.push(seconds);

  return timeArr.map((t, i) => (!i ? t : String(t).padStart(2, '0'))).join(':');
};

const Yesterday = ({toggleShowYesterday, game, wordList}) => {
  const answers = [...game.answers].sort();
  return (
    <div className={css.yesterday}>
      {answers.map((answer) => {
        const className = classNames({
          [css.yesterdayWord]: true,
          [css.yesterdayWord_found]: wordList.includes(answer),
        });

        return (
          <div className={className} key={answer}>
            {answer}
          </div>
        );
      })}

      <div className={css.yesterdayClose} onClick={toggleShowYesterday}>
        ✕
      </div>
    </div>
  );
};

const ElapsedTime = ({}) => {
  const {elapsedSeconds} = useElapsedTime();

  return <MenuItem title="Elapsed Time">{getTime(elapsedSeconds)}</MenuItem>;
};

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showYesterday, setShowYesterday] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const {
    calculatePoints,
    totalPoints,
    game,
    yesterday,
    yesterdayElapsedSecondsLastInteraction,
  } = useGameData();
  const {wordList, yesterdayWordList} = useWordList();
  const {showHints, setShowHints, showWordList, setShowWordList} = useHints();
  const {isDarkMode, setDarkMode} = useDarkMode();
  const {scale} = useTheme();

  const {points, ranking, nextRank, nextRankingPoints} = getInterestingGameData(
    game,
    wordList,
    calculatePoints
  );
  const {points: yesterdayPoints, ranking: yesterdayRanking} =
    getInterestingGameData(yesterday, yesterdayWordList, calculatePoints);

  const menuClassName = classNames({
    [css.menu]: true,
    [css.menu_closed]: !showMenu,
  });

  const toggleShowYesterday = () => {
    setShowYesterday(!showYesterday);
  };

  useEffect(() => {
    const onClick = (e) => {
      if (
        !e.target.classList.contains(css.menu) &&
        !e.target.closest('.' + css.menu) &&
        !e.target.classList.contains(css.yesterday) &&
        !e.target.closest('.' + css.yesterday) &&
        !e.target.classList.contains(css.menuToggle) &&
        !e.target.closest('.' + css.menuToggle)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [showMenu, setShowMenu]);

  const yesterdayTime = getTime(yesterdayElapsedSecondsLastInteraction);

  return (
    <>
      {showYesterday && (
        <Yesterday
          game={yesterday}
          toggleShowYesterday={toggleShowYesterday}
          wordList={yesterdayWordList}
        />
      )}
      <header className={css.header}>
        <Item name="Points" value={`${points}/${totalPoints}`} />
        <Item name="Ranking" value={ranking} />
        <Item name={`Next Lvl`} value={nextRankingPoints || '-'} />
        <div className={css.menuToggleWrapper}>
          <div
            className={css.menuToggle}
            onClick={() => setShowMenu(!showMenu)}
          >
            {!showMenu ? '☰' : '✕'}
          </div>
        </div>
      </header>
      <div className={menuClassName}>
        <ToggleItem
          onClick={setDarkMode}
          isChecked={isDarkMode}
          title="Dark Mode"
        />
        <ElapsedTime
          game={game}
          wordList={wordList}
          calculatePoints={calculatePoints}
        />
        <ToggleItem
          onClick={setShowWordList}
          isChecked={showWordList}
          title="Show Word List"
        />
        <ToggleItem
          onClick={setShowHints}
          isChecked={showHints}
          title="Show Hints"
        />
        {!!yesterdayElapsedSecondsLastInteraction && (
          <MenuItem onClick={toggleShowYesterday} title="Yesterday">
            {yesterdayRanking} in {yesterdayTime}
          </MenuItem>
        )}
        {false && (
          <>
            <MenuItem
              className={css.menuItem_last}
              onClick={() => setShowCalendar(!showCalendar)}
              title="Play Past Puzzles"
            >
              <div
                className={classNames({
                  [css.calClose]: true,
                  [css.calClose_show]: showCalendar,
                })}
              >
                ✕
              </div>
            </MenuItem>
            <Calendar showCalendar={showCalendar} />
          </>
        )}
      </div>
    </>
  );
};
