import classNames from 'classnames';
import {useState} from 'react';

import {Grid} from './Grid';
import {Header} from './Header';
import {useHints} from './HintContext';
import {WordList} from './WordList';
import {Entry} from './Entry';

import css from './Frame.module.scss';

const SingleFrame = ({children, className}) => {
  const combinedClassName = classNames(css.frame, className);

  return <div className={combinedClassName}>{children}</div>;
};

export const Frame = () => {
  const {showGrid, showHints, showWordList} = useHints();

  const hintsFrameClassName = classNames({
    [css.frame_hints]: true,
    [css.frame_hints_hide]: !showHints && !showWordList,
    [css.frame_grid]: showGrid,
    [css.frame_wordList]: showWordList && !showGrid,
  });

  const lettersClassName = classNames({
    [css.frame_letters]: true,
    [css.frame_letters_fullHeight]: !showHints && !showWordList,
  });

  return (
    <div className={css.frameContainer}>
      <div className={css.header}>
        <Header />
      </div>
      <SingleFrame className={hintsFrameClassName}>
        {showGrid || !showWordList ? <Grid /> : <WordList />}
      </SingleFrame>
      <SingleFrame className={lettersClassName}>
        <Entry />
      </SingleFrame>
    </div>
  );
};
