import {Frame} from './Frame';
import {GameDataProvider} from './GameDataContext';
import {HintContextProvider} from './HintContext';
import {ThemeContextProvider} from './ThemeContext';
import {WordListContextProvider} from './WordListContext';
import {ElapsedTimeProvider} from './ElapsedTimeContext';

import css from './App.module.scss';

try {
  window.screen.orientation.lock('portrait').catch((e) => {
    console.warn('could not lock screen');
  });
} catch (e) {
  console.warn('could not lock screen');
}

function App() {
  return (
    <GameDataProvider>
      <WordListContextProvider>
        <HintContextProvider>
          <ThemeContextProvider>
            <ElapsedTimeProvider>
              <div className={css.app}>
                <Frame />
              </div>
            </ElapsedTimeProvider>
          </ThemeContextProvider>
        </HintContextProvider>
      </WordListContextProvider>
    </GameDataProvider>
  );
}

export default App;
