import {useGameData} from './GameDataContext';
import {useWordList} from './WordListContext';

import css from './Grid.module.scss';

const Row = ({children}) => {
  return <tr className={css.row}>{children}</tr>;
};

const Cell = ({children}) => {
  return <td className={css.cell}>{children}</td>;
};

const GameDetail = ({title, children}) => {
  return (
    <div>
      <div className={css.detailTitle}>{title}</div>
      {children}
    </div>
  );
};

export const Grid = () => {
  const {game, grid} = useGameData();
  const {counts: wordListCounts, wordList} = useWordList();

  const foundPangrams = game.pangrams.reduce((sum, nextPangram) => {
    return wordList.includes(nextPangram) ? sum + 1 : sum;
  }, 0);

  const rows = grid.map((row, rowIndex) => {
    const isHeader = rowIndex === 0;
    const letter = row[0];

    const cells = row.map((original, colIndex, i) => {
      if (isHeader || !colIndex) {
        return <Cell key={[colIndex, original].join('-')}>{original}</Cell>;
      }

      const gridKey = `${letter}-${grid[0][colIndex]}`;
      const foundCount = wordListCounts[gridKey] || 0;

      return <Cell key={gridKey}>{original - foundCount || '-'}</Cell>;
    });

    return <Row key={letter}>{cells}</Row>;
  });

  return (
    <div className={css.gridViewContainer}>
      <div className={css.gameDetails}>
        <GameDetail title="Found words">
          {wordList.length}/{game.answers.length}
        </GameDetail>
        <GameDetail title="Pangrams">
          {foundPangrams}/{game.pangrams.length}
        </GameDetail>
      </div>
      <div className={css.gridWrapper}>
        <table className={css.grid}>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};
