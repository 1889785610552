import classNames from 'classnames';

import {Fragment, useLayoutEffect, useState} from 'react';

import {useGameData} from './GameDataContext';
import {useHints} from './HintContext';
import {useWordList} from './WordListContext';

import css from './WordList.module.scss';

export const WordListColumn = ({list, prefixSize}) => {
  const {game} = useGameData();

  return (
    <div className={css.wordList}>
      {list.map((text, i) => {
        if (!text) {
          return (
            <div className={css.word} key={`spacer-${i}`}>
              &nbsp;
            </div>
          );
        }

        const className = classNames({
          [css.word]: true,
          [css.word_pangram]: game.pangrams.includes(text),
          [css.word_found]: game.answers.includes(text),
        });

        return (
          <div className={className} key={`${text}-${i}`}>
            <strong>{text.substring(0, prefixSize)}</strong>
            <span>{text.substring(prefixSize)}</span>
          </div>
        );
      })}
    </div>
  );
};

export const WordList = () => {
  const {game} = useGameData();
  const {wordList} = useWordList();
  const {showGrid, showHints, showWordList} = useHints();
  const [splitIndex, setSplitIndex] = useState(game.answers.length);

  const [prefixSize, setPrefixSize] = useState(2);

  useLayoutEffect(() => {
    // Is it better to just generate the measurement instead
    if (!showWordList) {
      return;
    }

    if (splitIndex !== game.answers.length) {
      console.log('already called it once');
      return;
    }

    const measurementDiv = document.createElement('div');
    measurementDiv.classList.add(css.wordList);

    const container = document.getElementById('word-container');
    container.appendChild(measurementDiv);

    const {bottom: endOfListPosAlt} = measurementDiv.getBoundingClientRect();

    let breakIndex;
    for (breakIndex = 0; breakIndex < game.answers.length; breakIndex++) {
      const newWordDiv = document.createElement('div');
      newWordDiv.classList.add(css.word);
      newWordDiv.innerHTML = '&nbsp;';
      measurementDiv.appendChild(newWordDiv);

      const {bottom} = newWordDiv.getBoundingClientRect();
      if (bottom > endOfListPosAlt) {
        break;
      }
    }

    measurementDiv.remove();
    setSplitIndex(breakIndex);
  }, [showWordList, setSplitIndex]);

  const startingPairs = {};
  for (let word of game.answers) {
    const pair = word.substring(0, prefixSize);
    startingPairs[pair] = startingPairs[pair] || {
      allWords: [],
      foundWords: [],
    };

    startingPairs[pair].allWords.push(word);
    if (wordList.includes(word)) {
      startingPairs[pair].foundWords.push(word);
    }
  }

  const groupingData = Object.keys(startingPairs)
    .sort()
    .reduce((data, nextPair) => {
      data.push({
        pair: nextPair,
        ...startingPairs[nextPair],
      });
      return data;
    }, []);

  const columns = [[]];
  const addToColumn = (text) => {
    let currentColumn = columns.at(-1);

    // If the column has reached it's limit, create a new column.
    if (currentColumn.length >= splitIndex) {
      currentColumn = [];
      columns.push(currentColumn);
    }

    // If the first item in the column is a blank, skip it.
    if (!text && !currentColumn.length) {
      return;
    }

    // Does this next line mean we can remove the previous if statement?
    if (!text && !currentColumn.at(-1)) {
      return;
    }

    currentColumn.push(text);
  };

  for (let {foundWords, allWords, pair} of groupingData) {
    // For every group, add a separator.
    addToColumn('');
    for (let word of foundWords) {
      addToColumn(word);
    }

    const diff = allWords.length - foundWords.length;

    if (diff && showHints) {
      addToColumn(`${pair}:${'\u00A0'}${diff}`);
    }
  }

  return (
    <div className={css.wordContainer} id="word-container">
      {columns.map((column, i) => (
        <WordListColumn key={i} list={column} prefixSize={prefixSize} />
      ))}
    </div>
  );
};
