'use strict';
import {createContext, useContext, useEffect, useState} from 'react';

import {get, set} from './db';
import {useGameData} from './GameDataContext';

const WordListContext = createContext({});

const getWordList = async (gameId, setFunc) => {
  const {wordList} = await get({
    gameId,
    wordList: true,
  });

  setFunc(wordList || []);
};

export const WordListContextProvider = ({children}) => {
  const {game, yesterday} = useGameData();

  const [wordList, rawSetWordList] = useState([]);
  const [yesterdayWordList, setYesterdayWordList] = useState([]);

  const setWordList = async (wordList) => {
    rawSetWordList(wordList);
    await set({gameId: game.id, wordList});
  };

  const addWord = (word) => {
    const deduped = [...new Set([...wordList, word])];
    deduped.sort();

    setWordList(deduped);
  };

  useEffect(() => {
    getWordList(game.id, rawSetWordList);
  }, [game.id]);

  useEffect(() => {
    getWordList(yesterday.id, setYesterdayWordList);
  }, [yesterday.id]);

  const counts = wordList.reduce((counts, nextWord) => {
    const length = nextWord.length;
    const firstLetter = nextWord[0];
    const coupling = nextWord.substring(0, 2);
    const gridKey = `${firstLetter}-${length}`;

    counts[coupling] = (counts[coupling] || 0) + 1;
    counts[gridKey] = (counts[gridKey] || 0) + 1;

    return counts;
  }, {});

  const value = {
    addWord,
    counts,
    wordList,
    yesterdayWordList,
  };

  return (
    <WordListContext.Provider value={value}>
      {children}
    </WordListContext.Provider>
  );
};

export const useWordList = () => useContext(WordListContext);
