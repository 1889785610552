import {createContext, useContext, useEffect, useState} from 'react';

import {get, set} from './db';
import {useGameData} from './GameDataContext';

const HintContext = createContext({});

const getHintState = async (gameId, setFunc) => {
  const {showHints, showGrid, showWordList} = await get({
    gameId,
    showHints: true,
    showGrid: true,
    showWordList: true,
  });

  setFunc({
    showHints: showHints === undefined ? false : showHints,
    showGrid: showGrid === undefined ? false : showGrid,
    showWordList: showWordList === undefined ? true : showWordList,
  });
};

export const HintContextProvider = ({children}) => {
  const {game} = useGameData();

  const [{showHints, showGrid, showWordList}, rawSetContext] = useState({});

  useEffect(() => {
    getHintState(game.id, rawSetContext);
  }, [game.id, rawSetContext]);

  const setContext = (value) => {
    const newValue = {
      showHints,
      showGrid,
      showWordList,
      ...value,
    };

    rawSetContext(newValue);

    set({gameId: game.id, ...newValue});
  };

  const setShowHints = (outcome) => {
    setContext({
      showGrid: showWordList ? false : true,
      showHints: outcome,
    });
  };

  const setShowWordList = (outcome) => {
    setContext({
      showGrid: outcome ? false : showHints,
      showWordList: outcome,
    });
  };

  const setShowGrid = (outcome) => {
    setContext({
      showGrid: outcome,
    });
  };

  const value = {
    showGrid,
    showHints,
    setShowGrid,
    setShowHints,
    showWordList,
    setShowWordList,
  };
  return <HintContext.Provider value={value}>{children}</HintContext.Provider>;
};

export const useHints = () => useContext(HintContext);
