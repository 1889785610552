import axios from 'axios';
import {getMany, setMany} from 'idb-keyval';

const id = (gameId, key) => [gameId, key].join('|');

export const get = async ({gameId, ...args}) => {
  const keys = [...Object.keys(args)].filter((key) => args[key] !== undefined);
  const rawData = await getMany(keys.map((key) => id(gameId, key)));

  return rawData.reduce((data, nextData, i) => {
    data[keys[i]] = nextData;
    return data;
  }, {});
};

export const set = async ({gameId, ...args}) => {
  const entries = [...Object.entries(args)].filter(
    ([key, value]) => value !== undefined
  );

  const entriesForDb = entries.map(([key, value]) => {
    return [id(gameId, key), value];
  });

  await setMany(entriesForDb);
};
