import {createContext, useContext, useState} from 'react';
const ThemeContext = createContext({});

const definedVariables = Object.values(document.styleSheets).reduce(
  (set, sheet) => {
    // Sheets with other domains do not have cssRules which are accessible.
    if (sheet.href && !sheet.href.startsWith(window.location.origin)) {
      return set;
    }

    Object.values(sheet.cssRules).forEach((rule) => {
      if (rule.selectorText !== ':root') {
        return;
      }

      [...Object.values(rule.style)].forEach((val) => {
        if (val.startsWith('--')) {
          set.add(val);
        }
      });
    });

    return set;
  },
  new Set()
);

export const ThemeContextProvider = ({children}) => {
  const scale = (factor) => {
    const computedStyles = getComputedStyle(document.documentElement);

    const currentVariables = [...definedVariables].map((varName) => {
      const value = computedStyles.getPropertyValue(varName).trim();
      const numeric = parseFloat(value);

      return {
        name: varName,
        numeric,
        unit: value.replace(String(numeric), ''),
      };
    });

    currentVariables.forEach(({name, numeric, unit}) => {
      document.documentElement.style.setProperty(
        name,
        `${numeric * factor}${unit}`
      );
    });
  };

  const value = {scale};
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
