import {useEffect, useLayoutEffect, useState} from 'react';
import {get, set} from 'idb-keyval';

export const useDarkMode = () => {
  const [isDarkMode, _setDarkMode] = useState();

  useEffect(() => {
    const setUp = async () => {
      const value = await get('darkMode');

      console.log('VALUE IS', value, value || false);

      _setDarkMode(value || false);
    };

    setUp();
  }, []);

  console.log({isDarkMode});

  const setDarkMode = async (value) => {
    _setDarkMode(value);
    console.log('SET', value);

    await set('darkMode', value);
  };

  useLayoutEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('darkMode');
    } else {
      document.documentElement.classList.remove('darkMode');
    }
  }, [isDarkMode]);

  return {isDarkMode, setDarkMode};
};
